<section class="complains-form">
  <div class="tab">
    <button
      class="tablinks"
      [class.active]="selectedFormType === FORM_TYPES.DEFECT"
      (click)="selectFormType(FORM_TYPES.DEFECT)"
    >
      {{ "report-defect" | translate }}
    </button>
    <button
      class="tablinks"
      [class.active]="selectedFormType === FORM_TYPES.FRAUD"
      (click)="selectFormType(FORM_TYPES.FRAUD)"
    >
      {{ "report-fraud" | translate }}
    </button>
  </div>

  <form
    *ngIf="complaintsForm"
    [formGroup]="complaintsForm"
    (ngSubmit)="submitComplaintsForm()"
  >
    <p class="form-title">{{ "reporter_information" | translate }}</p>

    <div class="form-widget">
      <div class="form-widget-content-half">
        <label for="reporterName">{{ "name" | translate }}</label>
        <input
          id="reporterName"
          type="text"
          formControlName="reporterName"
          [ngClass]="{
            'invalid-input':
              controls?.['reporterName']?.invalid &&
              controls?.['reporterName']?.touched
          }"
        />
        <div
          *ngIf="controls?.['reporterName']?.touched && controls?.['reporterName']?.errors"
          class="error"
        >
          <span *ngIf="controls?.['reporterName']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 50 } }}
          </span>
          <span *ngIf="controls?.['reporterName']?.errors?.['pattern']">
            {{ "field-accept-chars" | translate }}
          </span>
        </div>
      </div>

      <div class="form-widget-content-half">
        <label for="reporterMobile">{{ "phone" | translate }}</label>
        <input
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="tel"
          minlength="9"
          maxlength="15"
          id="reporterMobile"
          class="form-widget-input"
          formControlName="reporterMobile"
          [ngClass]="{
            'invalid-input':
              controls?.['reporterMobile']?.invalid &&
              controls?.['reporterMobile']?.touched
          }"
        />
        <img src="/assets/images/sa-flag.svg" alt="sa flag" class="sa-flag" />
        <div
          *ngIf="controls?.['reporterMobile']?.touched && controls?.['reporterMobile']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['reporterMobile']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 15 } }}
          </p>
          <p *ngIf="controls?.['reporterMobile']?.errors?.['pattern']">
            {{ "number-field-error" | translate }}
          </p>
        </div>
      </div>
    </div>

    <div class="form-widget">
      <div class="form-widget-content">
        <label for="reporterEmail">{{ "email-address" | translate }}</label>
        <input
          id="reporterEmail"
          type="text"
          formControlName="reporterEmail"
          [ngClass]="{
            'invalid-input':
              controls?.['reporterEmail']?.invalid &&
              controls?.['reporterEmail']?.touched
          }"
        />
        <div
          *ngIf="controls?.['reporterEmail']?.touched && controls?.['reporterEmail']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['reporterEmail']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 254 } }}
          </p>
          <p *ngIf="controls?.['reporterEmail']?.errors?.['email']">
            {{ "email-error" | translate }}
          </p>
        </div>
      </div>
    </div>

    <p class="form-title">{{ "suspect_information" | translate }}</p>

    <div class="form-widget">
      <div class="form-widget-content-half">
        <label for="suspectName">{{ "name" | translate }}</label>
        <input
          id="suspectName"
          type="text"
          formControlName="suspectName"
          [ngClass]="{
            'invalid-input':
              controls?.['suspectName']?.invalid &&
              controls?.['suspectName']?.touched
          }"
        />
        <div
          *ngIf="controls?.['suspectName']?.touched && controls?.['suspectName']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['suspectName']?.errors?.['required']">
            {{ "required" | translate }}
          </p>
          <p *ngIf="controls?.['suspectName']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 50 } }}
          </p>
          <span *ngIf="controls?.['suspectName']?.errors?.['pattern']">
            {{ "field-accept-chars" | translate }}
          </span>
        </div>
      </div>

      <div class="form-widget-content-half">
        <label for="suspectProfession">{{ "profession" | translate }}</label>
        <input
          id="suspectProfession"
          class="form-widget-input"
          formControlName="suspectProfession"
          [ngClass]="{
            'invalid-input':
              controls?.['suspectProfession']?.invalid &&
              controls?.['suspectProfession']?.touched
          }"
        />
        <div
          *ngIf="controls?.['suspectProfession']?.touched && controls?.['suspectProfession']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['suspectProfession']?.errors?.['required']">
            {{ "required" | translate }}
          </p>
          <p *ngIf="controls?.['suspectProfession']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 50 } }}
          </p>
          <span *ngIf="controls?.['suspectProfession']?.errors?.['pattern']">
            {{ "field-accept-chars" | translate }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-widget">
      <div class="form-widget-content-half">
        <label for="suspectMobile">{{ "phone" | translate }}</label>
        <input
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="tel"
          minlength="9"
          maxlength="15"
          id="suspectMobile"
          class="form-widget-input"
          formControlName="suspectMobile"
          [ngClass]="{
            'invalid-input':
              controls?.['suspectMobile']?.invalid &&
              controls?.['suspectMobile']?.touched
          }"
        />
        <img src="/assets/images/sa-flag.svg" alt="sa flag" class="sa-flag" />
        <div
          *ngIf="controls?.['suspectMobile']?.touched && controls?.['suspectMobile']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['suspectMobile']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 15 } }}
          </p>
          <p *ngIf="controls?.['suspectMobile']?.errors?.['pattern']">
            {{ "number-field-error" | translate }}
          </p>
        </div>
      </div>

      <div class="form-widget-content-half">
        <label for="incidentTypeId">
          {{
            (selectedFormType === FORM_TYPES.FRAUD
              ? "fraud-channel"
              : "incident-type"
            ) | translate
          }}
        </label>
        <ngx-select-dropdown
          instanceId="incidentTypeId"
          [config]="dropdownConfig"
          [options]="incidentTypes"
          formControlName="incidentTypeId"
          [ngClass]="{
            'invalid-input':
              controls?.['incidentTypeId']?.invalid &&
              controls?.['incidentTypeId']?.touched
          }"
        ></ngx-select-dropdown>
        <div
          *ngIf="controls?.['incidentTypeId']?.touched && controls?.['incidentTypeId']?.errors?.['required']"
          class="error"
        >
          {{ "required" | translate }}
        </div>
      </div>
    </div>

    <div class="form-widget">
      <div class="form-widget-content">
        <label for="incidentDescription">
          {{
            (selectedFormType === FORM_TYPES.FRAUD
              ? "description"
              : "incident-body"
            ) | translate
          }}
        </label>
        <textarea
          id="incidentDescription"
          formControlName="incidentDescription"
          [ngClass]="{
            'invalid-input':
              controls?.['incidentDescription']?.invalid &&
              controls?.['incidentDescription']?.touched
          }"
          rows="4"
        ></textarea>
        <div
          *ngIf="controls?.['incidentDescription']?.touched && controls?.['incidentDescription']?.errors"
          class="error"
        >
          <p *ngIf="controls?.['incidentDescription']?.errors?.['required']">
            {{ "required" | translate }}
          </p>
          <p *ngIf="controls?.['incidentDescription']?.errors?.['maxlength']">
            {{ "max-length-is-error" | translate : { length: 1000 } }}
          </p>
        </div>
      </div>
    </div>

    <div class="compliant-button-container">
      <button
        [disabled]="complaintsForm.invalid || loading"
        class="submit-button"
      >
        {{ "report" | translate }}
      </button>
    </div>
  </form>
</section>
