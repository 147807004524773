import { Component, Input, OnInit } from '@angular/core';
import { ChangeLangService } from 'src/app/services/change-lang/change-lang.service';
import { FooterService } from './services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentLang = localStorage.getItem('lang') || "ar";
  footerData :any;
  @Input() pageTitle: string = '';


  constructor(
    private changelang:ChangeLangService,
    private footerServices: FooterService
    ) { }

  ngOnInit(): void {
    this.getFooterMainData();
  }

  switchLang(lang:any){
    this.changelang.changeLangage(lang.target.value)
  }

  getFooterMainData(){
    this.footerServices.getFooterData().subscribe((res:any)=>{
      this.footerData = res;
    })
  }

}
