import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeLangService {
  currentLang = localStorage.getItem('lang') || "ar";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService
  ) { }

  changeLangage(lang: any , base? :string) {
    console.log("lang" , lang);
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    localStorage.setItem('lang', lang);
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    const body = document.querySelector("body")!;
    body.className = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
    this.currentLang = localStorage.getItem('lang') || "ar";
    if(base != 'appComponent'){
      window.location.reload()
    }
  }
}
