<div class="cover">
  <div class="container d-flex">
    <div class="cover-images">
      <div class="users">
        <img src="../../../assets/images/users-img.svg" alt="">
        <p>
            <label><span class="plus">+</span>14 <span class="thousand">{{'million' | translate}}</span> </label>
            <span class="user">{{ 'used-in-the-Kingdom-through-deferred-payment' | translate}}</span>
        </p>
    </div>
    <img class="main-img" src="../../../assets/images/home-pg.svg" alt="">
    </div>

    <div class="cover-data">
<!--      <p class="sub-title"><span>* </span> كاش باك ومكافآت</p>-->
      <h1>{{'Pay-with-madfu-and-pay-later' | translate}}</h1>
      <div class="buttons">
        <button routerLink="/be-partner" class="be-partner-button">
          <img src="../../../assets/images/send-square.svg" alt="send square" />
          {{'register-as-a-partner' | translate}}
        </button>

        <div>
          <a
            target="_blank"
            href="https://app.adjust.com/1fz1h1en"
            ><img src="../../../assets/images/app-store-cover.svg" alt=""
          /></a>
          <a
            target="_blank"
            href="https://app.adjust.com/1fz1h1en"
            ><img src="../../../assets/images/google-cover.svg" alt=""
          /></a>
          <p>{{'download-the-app-now' | translate }}</p>
        </div>
      </div>

      <ul class="features">
        <li>
          <div>
            <img src="../../../assets/images/cover-1.svg" alt="" />
            <p class="option-title">{{'payment-options' | translate }}</p>
          </div>
          <span
            >{{'payment-options-desc' | translate }}</span
          >
        </li>
        <li>
          <div>
            <img src="../../../assets/images/cover-2.svg" alt="" />
            <p class="option-title">{{'sharia-compliant' | translate }}</p>
          </div>
          <span
            >{{'sharia-compliant-desc' | translate }}</span
          >
        </li>
        <li>
          <div>
            <img src="../../../assets/images/cover-3.svg" alt="" />
            <p class="option-title">{{'register-and-explore' | translate }}</p>
          </div>
          <span
            >{{'register-and-explore-desc' | translate }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="options">
  <div class="container">
    <div class="options-wrapper d-flex">
      <div class="choose-option">
        <h3>{{'choose-the-category-that-suits-you' | translate }}</h3>

        <div class="slider">
          <owl-carousel-o [options]="customOptionsSliderOne">
            <ng-template
              carouselSlide
              *ngFor="let category of sliderCategories"
            >
              <div class="slider-item">
                <img
                  [src]="'../../../assets/images/' + category.image"
                  [alt]="category.title"
                />
                <p>{{ category.title }}</p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>

        <img
          class="logo"
          [src]="currentLang === 'ar' ? '../../../assets/images/logo-cutout.svg' : '../../../assets/images/logo-cutout-en.svg'"

          alt="madfu"
        />
      </div>

      <div class="all-options">
        <div class="all-options-wrapper d-flex">
          <div class="option" *ngFor="let category of categories">
            <img
              class="image"
              [src]="'../../../assets/images/' + category.image"
              [alt]="category.title"
            />
            <p class="title">{{ category.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="islamic-law">
  <div class="container">
    <div class="islamic-law-widget d-flex">
      <img src="../../../assets/images/islamic-law-img.svg" alt="" />

      <div class="islamic-law-data">
        <h3>{{'compatible-with-islamic-sharia' | translate }}</h3>
        <p>
          {{'compatible-with-islamic-sharia-desc' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="payment-way">
  <div class="container">
    <div class="payment-way-widget d-flex">
      <h2 class="title">{{'pay-your-way' | translate }}</h2>
      <p class="sub-title">
        {{'pay-your-way-desc' | translate }}
      </p>

      <div class="data d-flex">
        <div class="data-widget installments">
          <span>{{'divide-into-several-batches' | translate }}</span>
          <h4>{{'split-up-to-6-payments' | translate }}</h4>
          <label>{{'hidden-fees' | translate }}</label>
        </div>

        <div class="payment-options">
          <div class="payment-options-widget">
<!--            <div class="option full-payment">-->
<!--              <div class="option-data">-->
<!--                <div class="details">-->
<!--                  <p class="title">دفع كامل</p>-->
<!--                  <p class="subtitle">ادفع كامل المبلغ و أحصل على كاش باك</p>-->
<!--                </div>-->

<!--                <img-->
<!--                  class="icon"-->
<!--                  src="../../../assets/images/go-throw.svg"-->
<!--                  alt="go throw"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

            <div class="option installment-payment">
              <div class="option-data">
                <div class="details">
                  <p class="title">{{ 'installment-payments' | translate}}</p>
                  <p class="subtitle">{{ 'divide-into-equal-amounts' | translate}}</p>
                </div>

                <button (click)="togglePaymentExpanded(1)">
                  <img
                    class="icon"
                    [ngClass]="{ rotate: paymentExpandedId === 1 }"
                    src="../../../assets/images/down-arrow.svg"
                    alt="down arrow"
                  />
                </button>
              </div>

              <div class="installment-options" *ngIf="paymentExpandedId === 1">
                <div class="installment-option">
                  <p class="installments"><span></span>{{ '3-payments' | translate}}</p>
                  <p class="pay-now-amount">{{ 'Pay-now-50-riyals' | translate}}</p>
                </div>

                <div class="installment-option">
                  <p class="installments"><span></span>{{ '4-payments' | translate}}</p>
                  <p class="pay-now-amount">{{ 'pay-now-37-riyals' | translate}}</p>
                </div>

                <div class="installment-option">
                  <p class="installments"><span></span>{{ '5-payments' | translate}}</p>
                  <p class="pay-now-amount">{{ 'pay-now-30-riyals' | translate}}</p>
                </div>
              </div>
            </div>

            <div class="option salary-day">
              <div class="option-data">
                <div class="details">
                  <p class="title">{{ 'salary-day' | translate}}</p>
                  <p class="subtitle">{{ 'pay-a-down-payment -nd-the-rest-later' | translate}}</p>
                </div>

                <button (click)="togglePaymentExpanded(2)">
                  <img
                    class="icon"
                    [ngClass]="{ rotate: paymentExpandedId === 2 }"
                    src="../../../assets/images/down-arrow.svg"
                    alt="down arrow"
                  />
                </button>
              </div>

              <div class="salary-day-payment" *ngIf="paymentExpandedId === 2">
                <img
                  src="../../../assets/images/payment-counter.svg"
                  alt="payment counter"
                />

                <div class="available-amount-widget">
                  <p class="available-amount">{{ 'available-value' | translate}}</p>
                  <p class="amount"><span>80</span> {{ 'riyal' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="data-widget salary">
          <span>{{'salary-day-offers' | translate }}</span>
          <h4>{{'salary-day-offers-desc' | translate }}</h4>
          <label>{{'smooth-and-hassle-free' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="offers">
  <div class="container">
    <div class="offers-widget">
      <div class="offers-data">
        <h3>{{'exclusive-discounts-and-rewards' | translate}}</h3>
        <p>
          {{'exclusive-discounts-and-rewards-desc' | translate}}
        </p>
      </div>

      <div class="offers-partners d-flex">
        <img class="img" src="../../../assets/images/offers-img.svg" alt="" />
        <div class="partners-widget d-flex">
          <h3>{{'our-partners-offers' | translate}}</h3>
          <p class="weekly-exclusive">{{'you-can-find-it-through-our-accounts' | translate}}</p>
          <p class="trusted-from">{{'trusted-by' | translate}}</p>

          <div class="trusted-from-widget">
            <img src="../../../assets/images/youtube.svg" alt="facebook" />
            <img src="../../../assets/images/face-book.svg" alt="facebook" />
            <img src="../../../assets/images/google.svg" alt="google" />
          </div>

          <a target="_blank" href="https://www.instagram.com/enjoy_madfu/">
            <img
              src="../../../assets/images/clipboard-export.svg"
              alt="اكتشف"
            />
            {{'discover' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="our-main-partners">
  <div class="container">
    <div class="our-main-partners-container">
      <h2 class="title">{{'our-partners' | translate}}</h2>
      <p class="sub-title">
        {{'our-partners-desc' | translate}}
      </p>
      <div class="main-tabs">
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
          {{'store' | translate}}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{'online' | translate}}
          </button>
        </div>

        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners">
              <p>{{currentLang == 'ar' ?  store.titleAr : store.titleEn}}</p>
              <img
              [src]="'../../../assets/images/store/' + store.image"
              alt=""
              />
              <a target="_blank" [href]="discoverLink">{{'explore' | translate}}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners">
              <p>{{currentLang == 'ar' ?  store.titleAr : store.titleEn}}</p>
              <img
                [src]="'../../../assets/images/online/' + store.image"

                alt=""
              />
              <a target="_blank" [href]="discoverLink">{{'explore' | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="download-app-now">
  <div class="container">
    <div class="download-app-now-widget d-flex">
      <div class="download-app-now-data">
        <h3>{{'download-the-app-now' | translate}}</h3>
        <p>
          {{'download-the-app-now-desc' | translate}}
        </p>
        <ul class="download">
          <li>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.sa.app.madfuser"
              class="d-flex"
            >
              <img
                src="../../../assets/images/download-widget-playstore.svg"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268"
              class="d-flex"
            >
              <img
                src="../../../assets/images/download-widget-appstore.svg"
                alt=""
              />
            </a>
          </li>
        </ul>
        <ul class="statistics">
          <li>
            <p class="title-top">
              {{'Upto' | translate}}
            </p>
            <p class="number">4%</p>
            <p class="title"> {{'cashback' | translate}}</p>
          </li>
          <li>
            <p class="title-top">
              {{'Upto' | translate}}
            </p>
            <p class="number">6</p>
            <p class="title">
              {{'interest-free-payments' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <img
        class="widget-img"
        src="../../../assets/images/download-app-now-widget-img.svg"
        alt=""
      />
    </div>
  </div>
</div>

<div class="know-you">
  <div class="container">
    <div class="know-you-widget d-flex">
      <div class="know-you-data">
        <h1>{{'we-introduce-you-and-increase-your-sales' | translate}}</h1>
        <ul>
          <li>{{'make-it-easier' | translate}}</li>
          <li>{{'reach-a-larger' | translate}}</li>
          <li>{{'increase-your-average' | translate}}</li>
        </ul>

        <button routerLink="/be-partner" class="be-partner-button">
          <img src="../../../assets/images/send-square.svg" alt="send square" />
          {{'register-as-a-partner' | translate}}
        </button>
      </div>

      <div class="madfu-partners">
        <div class="text">
          <p>{{'madfu-partners' | translate}}</p>
          <h3>{{'add-madfu-to-your-store-payment-methods' | translate}}</h3>
          <img src="../../../assets/images/small-car.svg" alt="small car" />
        </div>

        <ul>
          <li>
            <a>
              <img
                src="../../../assets/images/store/store-FU0AmyoWAAMTnt4.png"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              ><img src="../../../assets/images/store/store-qasr.png" alt=""
            /></a>
          </li>
          <li>
            <a
              ><img src="../../../assets/images/store/store-shaker.png" alt=""
            /></a>
          </li>
          <li>
            <a
              ><img src="../../../assets/images/store/store-elsaif.png" alt=""
            /></a>
          </li>
          <li>
            <a
              ><img
                src="../../../assets/images/online/online-almanea.png"
                alt=""
            /></a>
          </li>
          <li>
            <a
              ><img src="../../../assets/images/online/online-duvet.png" alt=""
            /></a>
          </li>
          <li>
            <a
              ><img
                src="../../../assets/images/online/online-sayara-tech.png"
                alt=""
            /></a>
          </li>
          <li>
            <a
              ><img src="../../../assets/images/online/online-clara.png" alt=""
            /></a>
          </li>
          <li>
            <a
              ><img
                src="../../../assets/images/online/online-car-station.png"
                alt=""
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
