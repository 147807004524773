<div class="blogs-cover">
  <div class="container">
    <div class="blogs-cover-widget d-flex">
      <h1>{{'latest-news' | translate}}</h1>
      <img src="../../../assets/images/blogs-img.svg" alt="" />
    </div>
  </div>
</div>

<div class="blogs">
  <div class="container">
    <div class="blogs-container">
      <ul>
        <ng-container *ngFor="let item of blogs; index as i">
          <app-blog [blog]="item" [isEven]="i % 2 === 0"></app-blog>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
