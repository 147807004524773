import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  menuOpen: boolean = false;
  @Input() pageTitle: string = '';

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });
  }

  get isHome() {
    return this.router.url === '/home' || this.router.url === '/';
  }

  get logoImageName() {
    return this.isHome ? 'madfu-logo-home.svg' : 'madfu-logo.svg';
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
