import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqList } from 'src/app/interfaces/faq';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private http: HttpClient) {}

  getCategories(): Observable<any> {
    return this.http.get<FaqList>('assets/data/faq.json');
  }
}
