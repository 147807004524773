<li [ngClass]="{ reverse: isEven }" class="blog-item">
  <img *ngIf="!isEven && blog.img" class="blog-img" [src]="blog.img" alt="" />
  <video *ngIf="!isEven && blog.video" class="blog-img" controls>
    <source [src]="blog.video" />
  </video>

  <div class="blogs-data">
    <h2>{{ currentLang == 'ar' ? blog.titleAr  : blog.titleEn }}</h2>
    <div class="html-content" [innerHTML]="blogViewedContent"></div>
    <div class="date-and-actions">
      <p>
        <img src="../../../assets/images/heart.svg" alt="" />
        <span>{{ currentLang == 'ar' ?  (blog.date | date : "dd MMM yyyy" : "" : "ar") : blog.date | date : "dd MMM yyyy" }}</span>
      </p>
      <button (click)="changeIsExpanded()">
        {{ isExpanded ? ('show-less' | translate) : ('read-more' | translate) }}
      </button>
    </div>
  </div>
  <img *ngIf="isEven && blog.img" class="blog-img" [src]="blog.img" alt="" />
  <video *ngIf="isEven && blog.video" class="blog-img" controls>
    <source [src]="blog.video" />
  </video>
</li>
