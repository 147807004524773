import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangeLangService } from 'src/app/services/change-lang/change-lang.service';

@Component({
  selector: 'app-email-confirmation-or-expired',
  templateUrl: './email-confirmation-or-expired.component.html',
  styleUrls: ['./email-confirmation-or-expired.component.scss'],
})
export class EmailConfirmationOrExpiredComponent implements OnInit {
  success = false;
  language = 'en';

  title = 'email-confirmation-failure-title';
  message = 'email-confirmation-failure-message';
  image = '/assets/images/expired-time.png';

  constructor(
    private readonly route: ActivatedRoute,
    private changelang: ChangeLangService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams['success'] === 'true') {
      this.success = true;
    }

    const urlLang = queryParams['lang'];
    if (['ar', 'en'].includes(urlLang)) {
      this.language = this.route.snapshot.queryParams['lang'];
      this.switchLang();
    }

    this.setData();
  }

  setData() {
    this.setContent();
    this.setImage();
  }

  switchLang() {
    if (this.language !== localStorage.getItem('lang')) {
      this.changelang.changeLangage(this.language);
    }
  }

  setContent() {
    if (this.success) {
      this.title = 'email-confirmation-success-title';
      this.message = 'email-confirmation-success-message';
    } else {
      this.title = 'email-confirmation-failure-title';
      this.message = 'email-confirmation-failure-message';
    }
  }

  setImage() {
    if (this.success) {
      this.image = '/assets/images/confirmation.png';
    } else {
      this.image = '/assets/images/expired-time.png';
    }
  }
}
