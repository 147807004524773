<div class="be-partner-cover">
  <div class="container">
    <div class="be-partner-cover-widget d-flex">
      <div class="be-partner-data">
        <h1>{{ "we-introduce-you-and-increase-your-sales" | translate }}</h1>
        <p>
          {{ "we-introduce-you-and-increase-your-sales-desc" | translate }}
        </p>
        <button class="be-partner-button" (click)="scrollToForm()">
          <img src="../../../assets/images/send-square.svg" alt="send square" />
          {{ "register-as-a-partner" | translate }}
        </button>
      </div>
      <img
        class="be-partner"
        [src]="'../../../assets/images/be-parnter-img-' + currentLang + '.svg'"
        alt="be partner"
      />
    </div>
  </div>
</div>

<div class="growth-together">
  <div class="container">
    <div class="growth-together-widget">
      <h2>{{ "we-grow-together" | translate }}</h2>
      <p>
        {{
          "madfu-Your-best-financial-partner-for-your-store-payments"
            | translate
        }}
      </p>
      <owl-carousel-o [options]="customOptionsSlider">
        <ng-template carouselSlide *ngFor="let item of growthData">
          <div class="growth-together-widget-item">
            <img [src]="'../../../assets/images/' + item.image" alt="" />
            <div>
              <h4>{{ currentLang == "ar" ? item.titleAr : item.titleEn }}</h4>
              <p>
                {{ currentLang == "ar" ? item.descAr : item.descEn }}
              </p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</div>

<div class="our-main-partners-be-partner-page d-flex">
  <div class="container">
    <div class="our-main-partners-container d-flex">
      <div class="main-tabs">
        <h2 class="title">{{ "our-partners" | translate }}</h2>
        <p class="sub-title">
          {{ "our-partners-desc" | translate }}
        </p>
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
            {{ "store" | translate }}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{ "online" | translate }}
          </button>
        </div>
        <ul>
          <li>{{ "make-it-easier" | translate }}</li>
          <li>{{ "reach-a-larger" | translate }}</li>
          <li>
            {{ "increase-your-average" | translate }}
          </li>
        </ul>
      </div>
      <div class="tabs-content-widgets">
        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners | slice : 0 : 4">
              <p>{{ currentLang == "ar" ? store.titleAr : store.titleEn }}</p>
              <img
                [src]="'../../../assets/images/store/' + store.image"
                alt=""
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners | slice : 0 : 4">
              <p>{{ currentLang == "ar" ? store.titleAr : store.titleEn }}</p>
              <img
                [src]="'../../../assets/images/online/' + store.image"
                alt=""
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="join-us" class="join-us">
  <div class="container">
    <div class="join-us-widget d-flex">
      <div class="form">
        <h3>{{ "share-our-success-and-join-our-partners" | translate }}</h3>
        <p class="subtitle">
          {{ "ready-to-increase-your-sales-register-now" | translate }}
        </p>

        <ul class="stepper">
          <li [class.active]="step >= 1">{{ "merchant-info" | translate }}</li>
          <li [class.active]="step >= 2">
            {{ "store-owner-info" | translate }}
          </li>
          <li [class.active]="step === 3">{{ "documents" | translate }}</li>
        </ul>

        <p class="form-title">
          <span *ngIf="step === 1">
            {{ "merchant-information" | translate }}
          </span>
          <span *ngIf="step === 2">
            {{ "store-rRepresentative-information" | translate }}
          </span>
          <span *ngIf="step === 3">{{ "documents" | translate }}</span>
        </p>

        <form
          *ngIf="merchantInformationForm && step === 1"
          [formGroup]="merchantInformationForm"
          (ngSubmit)="nextButtonHandler()"
        >
          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'merchant-name' | translate"
                formControlName="merchantName"
                [ngClass]="{
                  'invalid-input':
                    merchantInformationForm.get('merchantName')?.invalid &&
                    merchantInformationForm.get('merchantName')?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    merchantInformationForm.get('merchantName')?.invalid &&
                    merchantInformationForm.get('merchantName')?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="merchantInformationForm.get('merchantName')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('merchantName')?.errors?.['pattern']"
                  >
                    {{
                      "field-must-contains-only-letters-spaces-and-numbers"
                        | translate
                    }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('merchantName')?.errors?.['maxlength']"
                  >
                    {{ "max-length-is-error" | translate : { length: 25 } }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'brand-name' | translate"
                formControlName="brandName"
                [ngClass]="{
                  'invalid-input':
                    merchantInformationForm.get('brandName')?.invalid &&
                    merchantInformationForm.get('brandName')?.touched
                }"
              />

              <div class="error-space">
                <div
                  *ngIf="
                    merchantInformationForm.get('brandName')?.invalid &&
                    merchantInformationForm.get('brandName')?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="merchantInformationForm.get('brandName')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('brandName')?.errors?.['pattern']"
                  >
                    {{
                      "field-should-contains-chars-spaces-and-special-chars"
                        | translate
                    }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('brandName')?.errors?.['maxlength']"
                  >
                    {{ "max-length-is-error" | translate : { length: 50 } }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content">
              <input
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="tel"
                class="form-widget-input"
                [placeholder]="'branches-number' | translate"
                formControlName="numberOfBranches"
                minlength="0"
                maxlength="4"
                [ngClass]="{
                  'invalid-input':
                    merchantInformationForm.get('numberOfBranches')?.invalid &&
                    merchantInformationForm.get('numberOfBranches')?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    merchantInformationForm.get('numberOfBranches')?.invalid &&
                    merchantInformationForm.get('numberOfBranches')?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="merchantInformationForm.get('numberOfBranches')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="
                      merchantInformationForm.get('numberOfBranches')?.errors?.['min']
                    "
                  >
                    {{ "branches-number-is-should-be-numbers" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'commercial-registration-number' | translate"
                formControlName="commercialRegistrationNumber"
                [ngClass]="{
                  'invalid-input':
                    merchantInformationForm.get('commercialRegistrationNumber')
                      ?.invalid &&
                    merchantInformationForm.get('commercialRegistrationNumber')
                      ?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    merchantInformationForm.get('commercialRegistrationNumber')
                      ?.invalid &&
                    merchantInformationForm.get('commercialRegistrationNumber')
                      ?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="merchantInformationForm.get('commercialRegistrationNumber')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('commercialRegistrationNumber')?.errors?.['pattern']"
                  >
                    {{ "field-alphanumeric-with-dashes" | translate }}
                  </span>
                  <span
                    *ngIf="merchantInformationForm.get('commercialRegistrationNumber')?.errors?.['maxlength']"
                  >
                    {{ "max-length-is-error" | translate : { length: 15 } }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <button
            [disabled]="merchantInformationForm.invalid"
            class="next-button full"
          >
            {{ "next" | translate }}
            <img src="assets/images/baby-blue-arrow-right.svg" alt="arrow" />
          </button>
        </form>

        <form
          *ngIf="storeRepresentativeInformationForm && step === 2"
          [formGroup]="storeRepresentativeInformationForm"
        >
          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'relationship-to-store' | translate"
                formControlName="relationshipToTheStore"
                [ngClass]="{
                  'invalid-input':
                    storeRepresentativeInformationForm.get(
                      'relationshipToTheStore'
                    )?.invalid &&
                    storeRepresentativeInformationForm.get(
                      'relationshipToTheStore'
                    )?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    storeRepresentativeInformationForm.get(
                      'relationshipToTheStore'
                    )?.invalid &&
                    storeRepresentativeInformationForm.get(
                      'relationshipToTheStore'
                    )?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('relationshipToTheStore')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('relationshipToTheStore')?.errors?.['pattern']"
                  >
                    {{
                      "field-should-contains-chars-spaces-and-special-chars"
                        | translate
                    }}
                  </span>
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('relationshipToTheStore')?.errors?.['maxlength']"
                  >
                    {{ "max-length-is-error" | translate : { length: 20 } }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content-half">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'name' | translate"
                formControlName="name"
                [ngClass]="{
                  'invalid-input':
                    storeRepresentativeInformationForm.get('name')?.invalid &&
                    storeRepresentativeInformationForm.get('name')?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    storeRepresentativeInformationForm.get('name')?.invalid &&
                    storeRepresentativeInformationForm.get('name')?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('name')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('name')?.errors?.['pattern']"
                  >
                    {{
                      "field-must-contains-only-letters-spaces-and-numbers"
                        | translate
                    }}
                  </span>
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('name')?.errors?.['maxlength']"
                  >
                    {{ "max-length-is-error" | translate : { length: 25 } }}
                  </span>
                </div>
              </div>
            </div>

            <div class="form-widget-content-half">
              <input
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="tel"
                minlength="0"
                maxlength="10"
                class="form-widget-input"
                [placeholder]="'id-number' | translate"
                formControlName="idNumber"
                [ngClass]="{
                  'invalid-input':
                    storeRepresentativeInformationForm.get('idNumber')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('idNumber')?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    storeRepresentativeInformationForm.get('idNumber')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('idNumber')?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('idNumber')?.errors?.['required']"
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="storeRepresentativeInformationForm.get('idNumber')?.errors?.['pattern']"
                  >
                    {{
                      "field-should-be-10-numbers-and-start-with-1-or-2"
                        | translate
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content-half">
              <input
                type="text"
                datepicker
                format="YYYY-MM-DD"
                formControlName="dateOfBirth"
                [placeholder]="'birth-date' | translate"
                class="form-widget-input"
                [ngClass]="{
                  'invalid-input':
                    storeRepresentativeInformationForm.get('dateOfBirth')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('dateOfBirth')
                      ?.touched
                }"
              />

              <div class="error-space">
                <div
                  *ngIf="
                    storeRepresentativeInformationForm.get('dateOfBirth')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('dateOfBirth')
                      ?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="
                      storeRepresentativeInformationForm.get('dateOfBirth')
                        ?.errors?.['required']
                    "
                  >
                    {{ "field-is-required" | translate }}
                  </span>
                  <span
                    *ngIf="
                      storeRepresentativeInformationForm.get('dateOfBirth')
                        ?.errors?.['minimumAge']
                    "
                  >
                    {{ "age-should-be-18" | translate }}
                  </span>
                </div>
              </div>
            </div>

            <div class="form-widget-content-half">
              <input
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="tel"
                minlength="9"
                maxlength="9"
                class="form-widget-input"
                [placeholder]="'phone' | translate"
                formControlName="phoneNumber"
                appLeadingZero
                [ngClass]="{
                  'invalid-input':
                    storeRepresentativeInformationForm.get('phoneNumber')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('phoneNumber')
                      ?.touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    storeRepresentativeInformationForm.get('phoneNumber')
                      ?.invalid &&
                    storeRepresentativeInformationForm.get('phoneNumber')
                      ?.touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="
                      (storeRepresentativeInformationForm.get('phoneNumber')
                        ?.errors)?.['required']
                    "
                  >
                    {{ "required" | translate }}
                  </span>
                  <span
                    *ngIf="
                      (storeRepresentativeInformationForm.get('phoneNumber')
                        ?.errors)?.['pattern']
                    "
                  >
                    {{ "phone-must-be-number" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="form-widget">-->
          <!--            <div class="form-widget-content">-->
          <!--              <input-->
          <!--                type="text"-->
          <!--                class="form-widget-input"-->
          <!--                [placeholder]="'freelancer-document-number' | translate"-->
          <!--                formControlName="freelancingDocumentNumber"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="buttons-container">
            <button
              class="previous-button"
              type="button"
              (click)="backButtonHandler()"
            >
              <img src="assets/images/blue-arrow-left.svg" alt="arrow" />
              <span>{{ "previous" | translate }}</span>
            </button>

            <button
              [disabled]="storeRepresentativeInformationForm.invalid"
              class="next-button"
              type="button"
              (click)="nextButtonHandler()"
            >
              {{ "next" | translate }}
              <img src="assets/images/baby-blue-arrow-right.svg" alt="arrow" />
            </button>
          </div>
        </form>

        <form
          *ngIf="attachmentsForm && step === 3"
          [formGroup]="attachmentsForm"
          (ngSubmit)="bePartnerFormMethod()"
        >
          <div class="form-widget">
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="commercialRegistration"
              >
              </app-file-picker>
            </div>
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="vatCertificate"
              >
              </app-file-picker>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="merchantBankAccount"
              >
              </app-file-picker>
            </div>
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="signatoriesPersonalId"
              >
              </app-file-picker>
            </div>
          </div>

          <div class="form-widget">
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="municipalityLicense"
              >
              </app-file-picker>
            </div>
            <div class="form-widget-content-half">
              <app-file-picker
                [formGroup]="attachmentsForm"
                fieldControl="eCommerceAuthenticationCertificate"
              >
              </app-file-picker>
            </div>
          </div>

          <!--          <div class="form-widget">-->
          <!--            <div class="form-widget-content">-->
          <!--              <app-file-picker-->
          <!--                [formGroup]="attachmentsForm"-->
          <!--                fieldControl="freelancerDocument"-->
          <!--              >-->
          <!--              </app-file-picker>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="buttons-container">
            <button
              class="previous-button"
              type="button"
              (click)="backButtonHandler()"
            >
              <img src="assets/images/blue-arrow-left.svg" alt="arrow" />
              <span>{{ "previous" | translate }}</span>
            </button>

            <button [disabled]="attachmentsForm.invalid" class="next-button">
              {{ "share" | translate }}
            </button>
          </div>
        </form>
      </div>
      <img
        class="be-partner-form-image"
        src="assets/images/be-partner-form.svg"
        alt=""
      />
    </div>
  </div>
</div>
