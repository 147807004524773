import { Component, OnInit } from '@angular/core';
import { BlogsService } from './service/blogs.service';
import { Blog } from 'src/app/interfaces/blog';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blogs: Blog[] = [];

  constructor(private blogsService: BlogsService) {}

  ngOnInit(): void {
    this.getBlogsData();
  }

  getBlogsData() {
    this.blogsService.getData().subscribe(
      (response: any) => {
        this.blogs = response;
      },
      (error: any) => {
        console.error('Error fetching data:', error);
      }
    );
  }
}
