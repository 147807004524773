import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../home/service/categories.service';

@Component({
  selector: 'app-islamic-committee',
  templateUrl: './islamic-committee.component.html',
  styleUrls: ['./islamic-committee.component.scss']
})
export class IslamicCommitteeComponent implements OnInit {
  shariaData:any;
  currentLang = localStorage.getItem('lang');


  constructor(
    private categoriesService : CategoriesService

  ) { }

  ngOnInit(): void {
    this.getShariaData();
  }

  getShariaData(){
    this.categoriesService.getShariaData().subscribe(
      (response: any) => {
        this.shariaData = response;
      }
    );
  }

}
