import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Blog } from 'src/app/interfaces/blog';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  private dataUrl = 'assets/data/blogs-data.json';

  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    return this.http.get<Blog[]>(this.dataUrl);
  }
}
