import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FaqComponent } from './pages/faq/faq.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { IslamicCommitteeComponent } from './pages/islamic-committee/islamic-committee.component';
import { BlogComponent } from './components/blogs/blog/blog.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { FilePickerComponent } from './components/partner/file-picker/file-picker.component';
import { FormComponent } from './components/complaints/form/form.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { InterceptorsService } from './interceptors/main';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatepickerModule } from '@handylib/ngx-datepicker';
import { EmailConfirmationOrExpiredComponent } from './pages/email-confirmation-or-expired/email-confirmation-or-expired.component';
import { LeadingZeroDirective } from './directives/leading-zero.directive';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    PartnerComponent,
    BlogsComponent,
    FaqComponent,
    PrivacyComponent,
    TermsAndConditionsComponent,
    IslamicCommitteeComponent,
    BlogComponent,
    ComplaintsComponent,
    FilePickerComponent,
    FormComponent,
    LoaderComponent,
    EmailConfirmationOrExpiredComponent,
    LeadingZeroDirective,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    ToastrModule.forRoot(),
    SelectDropDownModule,
    NgxDatepickerModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LdHGV8pAAAAAC7aotMjteXgyyTKlbMJJMqzDM2a',
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorsService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
