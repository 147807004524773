import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { IslamicCommitteeComponent } from './pages/islamic-committee/islamic-committee.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { EmailConfirmationOrExpiredComponent } from './pages/email-confirmation-or-expired/email-confirmation-or-expired.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'be-partner',
    component: PartnerComponent,
  },
  {
    path: 'blogs',
    component: BlogsComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'islamic-committee',
    component: IslamicCommitteeComponent,
  },
  {
    path: 'complaints',
    component: ComplaintsComponent,
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationOrExpiredComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
