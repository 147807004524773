import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  currentLang = localStorage.getItem('lang') || "en";

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

}
