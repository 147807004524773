import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { partner } from 'src/app/interfaces/category';
import { CategoriesService } from '../home/service/categories.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  deviceInfo: any;
  macOS = "mac";
  iosOS = "ios";
  discoverLink:string = '';
  currentLang = localStorage.getItem('lang');
  storePartners: partner [] = [];
  onlinePartners: partner [] = [];


  constructor(
    private deviceService: DeviceDetectorService,
    private categoriesService: CategoriesService

    ) {
    this.checkOS();
   }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById("defaultOpen")!.click();
    }, 500);
    this.getStorePartnersData();
    this.getOnlinePartnersData();
  }

  getStorePartnersData(){
    this.categoriesService.getStorePartners().subscribe(
      (response: any) => {
        this.storePartners = response;
      }
    );
  }
  getOnlinePartnersData(){
    this.categoriesService.getOnlinePartners().subscribe(
      (response: any) => {
        this.onlinePartners = response;
      }
    );
  }

  checkOS() {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    if(this.deviceInfo.os.toLocaleLowerCase() == this.macOS ||  this.deviceInfo.os.toLocaleLowerCase() == this.iosOS){
      this.discoverLink = 'https://app.adjust.com/1fz1h1en';
    }else{
      this.discoverLink = 'https://app.adjust.com/1fz1h1en';
    }
  }

  openItem(evt: MouseEvent, itemName: string): void {
    // Declare all variables
    let i: number;
    let tabcontent: HTMLCollectionOf<Element>;
    let tablinks: HTMLCollectionOf<Element>;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(itemName)!.style.display = "block";
    (evt.currentTarget as HTMLElement).className += " active";
  }

}
