import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appLeadingZero]',
})
export class LeadingZeroDirective {
  constructor(private control: NgControl) {}
  processInput(value: any) {
    if (
      value.toString()[3] &&
      value.toString()[0] == '0' &&
      value.toString()[1] == '5'
    ) {
      value = value.substring(1);
    }
    return value;
  }

  removeZero(value: any) {
    let str = value.toString();
    if (str.length > 1 && str[0] == '0') str = str.substring(1);

    return str;
  }

  @HostListener('input', ['$event'])
  onInput(e: any) {
    const modifiedValue = this.removeZero(e.target.value);
    this.control.valueAccessor?.writeValue(modifiedValue);
  }
}
