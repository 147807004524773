import { Component, Input, OnInit } from '@angular/core';
import { Blog } from 'src/app/interfaces/blog';
import ar from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  @Input() blog!: Blog;
  @Input() isEven: boolean = false;
  currentLang = localStorage.getItem('lang') || "en";


  isExpanded: boolean = false;

  constructor() {}

  ngOnInit(): void {
    registerLocaleData(ar);
  }

  get blogViewedContent() {
    return this.isExpanded ? (this.currentLang == 'ar' ?  this.blog.descAr :  this.blog.descEn ) : (this.currentLang == 'ar' ?  this.blog.shortDescAr :  this.blog.shortDescEn);
  }

  changeIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
